@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400;700&display=swap');

* {
  font-family: 'Lato', sans-serif !important;
  box-sizing: border-box;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

#root,
.App {
  height: 100%;
}

#root>div {
  height: 100%;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 10px;
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0.44, #004A54),
      color-stop(0.72, #004A54),
      color-stop(0.86, #004A54));
}

///////////// 
/// COMMON
/////////////

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-center-y {
  display: flex;
  align-items: center;
}

.flex-center-xy {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jcsb {
  justify-content: space-between;
}

label {
  font-weight: 700;
  margin: 4px;
}

.no-dec {
  text-decoration: none;
}

/////////////
// COMPONENTS
/////////////

#assetList a {
  text-decoration: none;
}

.assets-container {
  height: calc(100% - 55px);
  overflow-y: auto;
}


@media only screen and (min-width: 769px) {

  .asset-details,
  .asset-request-details,
  .asset-request-revenue-filter {
    width: 400px;
    margin: 0 auto;
  }
}